import React, { useEffect, useState } from "react";
import "./global.css";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import { UserStateContext } from "../pages";

const About = (props) => {
  return (
    <>
      <Container>
        <div css={main}>
          <img
            src={LogofromImagesFolder}
            alt="Adarsh Park Heights"
            style={{ width: "195px", height: "80px" }}
          />
          <div>
            <Title
              title="Adarsh Park Heights"
              size="1.5vw"
              color="#000"
              case="uppercase"
            />
            <p>
              <span style={{textAlign:"center"}}>Adarsh Park Highlights</span>
              Adarsh Park Heights is a luxury high-rise apartment project coming
              up in Gunjur Village Bangalore. Adarsh Park heights is a high
              profile project from Adarsh group itself, Over the past twenty
              years Adarsh has delivered over forty homes where thousands of
              happy customers reside. Adarsh Park heights is comming with 1BHk,
              2BHK & 3BHK apartments with different dimensions. It is designed
              in such a manner that it's design will provide exceptional life
              experiences, security and price. Adarsh properties have seen the
              absolute best rate of capital appreciation Associate in Nursingd
              have given our customers Associate in Nursing quality to possess
              for all times. With innovation, quality, and property at the core
              of our work and a unique consumer central approach has place us on
              the league of the simplest and most certain developers at
              intervals the business. Our expertise in land acquisition,
              understanding the consumer desires, execution capabilities along
              side the appointment of architects, designers, project managers
              along with exceptional sales and once sales service has helped us
              to be counted among the forerunners in assets development.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
  }
`;
